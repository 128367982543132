'use client';

import { captureException } from '@sentry/nextjs';
import { useEffect } from 'react';

// biome-ignore lint/suspicious/noShadowRestrictedNames: <explanation>
export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  useEffect(() => {
    captureException(error);
    console.error(error);
  }, [error]);

  return (
    <div>
      <span style={{ fontSize: '3rem', textAlign: 'center', display: 'block' }}>
        🙈
      </span>
      <h1 style={{ fontSize: '1.4rem', textAlign: 'center' }}>
        Ooops, something wrong happened
      </h1>
    </div>
  );
}
